.pia-center {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 100vh;
  padding: 10px;
  width: 100%;
}

.pia-info {
  font-size: 18px;
  margin: 15px 0;
}

.pia-info.invoice {
  font-size: 24px;
}

.pia-text-bold {
  font-weight: bold;
}

.pia-title {
  font-size: 28px;
}